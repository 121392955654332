.left-sidebar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
  width: 50px;
  height: 100%;
  border-right: 2px solid lightgray;
  background-color: white;
}

#company-logo {
  height: 25px;
  width: 25px;
  margin-top: 5px;
}

.left-sidebar-icon-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.sidebar-icons {
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-top: 10px;
}

.sidebar-icon-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* for the second left sidebar components */

.second-sidebar-components {
  display: flex;
}

.second-sidebar-component {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 50px;
  height: 100%;
  width: 260px;
  background-color: white;
}

.sidebar-logo-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 50px;
}
